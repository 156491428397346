<template>
  <a-spin :spinning="spinning">
    <a-row :gutter="20" v-if="privacyList.length">
      <a-col v-for="(item, index) in privacyList" :key="index" :span="12">
        <a-card class="m-b-20 height-140" hoverable>
          <p class="fz-16 p-b-8 ">{{ item.api_ch_name }}</p>
          <div class="s_flex_bian">
            <div class="s_c999 width80">
              {{ item.api_desc }}
              <a href="javascript:;" @click="lookMore(item.api_link)"
                >查看详情</a
              >
              <a-tooltip v-if="item.status == 4">
                <template #title>
                  <p>{{ item.fail_reason }}</p>
                </template>
                <a class="m-l-20" href="javascript:;">失败原因</a>
              </a-tooltip>
            </div>
            <a-button
              v-has:appletApplyPrivacy="() => open(item)"
              v-if="item.status == 1"
              >去开通</a-button
            >
            <p class="s_c999" v-if="item.status == 3">申请中</p>
            <p
              class="s_c999"
              v-has:appletApplyPrivacy="() => open(item)"
              v-if="item.status == 4"
            >
              申请失败
              <RightOutlined />
            </p>
            <p class="s_c999" v-if="item.status == 5">已开通</p>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <div class="s_flex_center" style="height:400px" v-else>
      <a-empty />
    </div>
  </a-spin>
  <a-modal
    v-model:visible="visible"
    centered
    destroyOnClose
    :width="1000"
    :footer="null"
  >
    <p class="fz-30">{{ form.api_name + "接口申请" }}</p>
    <p class="s_c999 m-b-24">
      你正在提交接口申请，所有信息请如实填写，所填信息只用于审核。
    </p>
    <a-form
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 15 }"
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <a-form-item ref="content" label="申请原因" name="content">
        <a-textarea
          v-model:value="form.content"
          showCount
          :maxlength="300"
          placeholder="详细描述接口能力的具体使用场景。"
          :rows="4"
        />
      </a-form-item>
      <a-form-item ref="pic_list" label="辅助图片" name="pic_list">
        <a-image v-for="v in form.pic_list" :key="v" :width="100" :src="v" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 8, offset: 3 }">
        <a-button type="primary" @click="onSubmit" :loading="loading">
          提交申请
        </a-button>
        <a-button class="m-l-20" @click="visible = false">
          取消
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { RightOutlined } from "@ant-design/icons-vue";
import { getPrivacyList, setApplyPrivacy } from "@/api/appletCode";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";
import { $iscode } from "@/utils/app";
import { onMounted, ref, inject, reactive, toRefs } from "vue";
import store from "@/store";
// 初始化默认筛选项数值
let defForm = {
  brand_id: "",
  appid: "",
  api_name: "",
  content: "",
  url_list: "",
  pic_list: [],
  video_list: ""
};
export default {
  components: { RightOutlined },
  setup() {
    const form = ref({ ...defForm });
    const state = reactive({
      info: store.state.user.info,
      privacyItem: {},
      visible: false,
      loading: false,
      rules: {
        content: [{ required: true, message: "请输入申请原因" }]
      }
    });
    const $route = inject("$route");
    const $router = inject("$router");
    const privacyList = ref([]);
    const spinning = ref(true);
    //查看详情
    const lookMore = (url) => {
      window.open(url);
    };
    //去开通
    const open = (data) => {
      form.value.api_name = data.api_name;
      if (form.value.api_name == "wx.getLocation") {
        form.value.content =
          "因当前业务涉及打卡定位和门店建档需要门店地址经纬度";
        form.value.pic_list = [
          state.info.picdomain + "/web_base_assets/getLocation1.png",
          state.info.picdomain + "/web_base_assets/getLocation2.png"
        ];
      }
      state.visible = true;
    };
    const ruleForm = ref();
    //开通
    const onSubmit = () => {
      ruleForm.value
        .validate()
        .then(async () => {
          const hide = message.loading("正在加载中...", 0);
          state.loading = true;
          let res = await setApplyPrivacy({
            ...form.value,
            brand_id: $route.query.brand_id,
            appid: $route.query.app_id,
            miniapp_type: $route.query.miniapp_type
              ? $route.query.miniapp_type
              : ""
          })
            .then((res) => res.data)
            .catch((error) => error);
          if ($iscode(res)) {
            message.success(res.msg);
            state.visible = false;
            initData();
          } else {
            message.error(res.msg);
          }
          setTimeout(hide);
          state.loading = false;
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    //获取列表
    const initData = async () => {
      spinning.value = true;
      let result = await getPrivacyList({
        brand_id: $route.query.brand_id,
        appid: $route.query.app_id,
        miniapp_type: $route.query.miniapp_type ? $route.query.miniapp_type : ""
      })
        .then((res) => res.data)
        .catch((error) => error);
      console.log(result);
      if ($iscode(result)) {
        privacyList.value = result.data.interface_list;
      }
      spinning.value = false;
    };
    onMounted(() => {
      initData();
    });
    return {
      ...toRefs(state),
      privacyList,
      spinning,
      form,
      open,
      onSubmit,
      ruleForm,
      lookMore
    };
  }
};
</script>
