<template>
  <a-card title="服务器域名" class='m-b-20'>
    <template #extra>
      <a-button type="primary" v-has:synSystemUrl="synchro">同步系统域名</a-button>
    </template>
    <div class="table_wrp config_table">
      <table cellspacing="0" class="table">
        <thead class="thead">
          <tr>
            <th colspan="2" class="table_cell">服务器配置</th>
            <th class="table_cell tl">说明</th>
            <!-- <th class="table_cell tr">操作</th> -->
          </tr>
        </thead>
        <tbody class="tbody">
          <tr>
            <td class="table_cell base_info">request合法域名</td>
            <td class="table_cell tl">
              <ul class="table_config_info_list">
                <li class="table_config_info_item" v-for="(v,k) in links.requestdomain" :key="k">
                  {{v.hosturl}}
                </li>
              </ul>
            </td>
            <td rowspan="5" class="table_cell tr">
              <div class="meta_tips">
                <p class="tips_global">每个月可修改50次</p>
              </div>
            </td>
            <!-- <td rowspan="5" class="table_cell tr">
                <div class="meta_opr"><a href="javascript:;" class="" v-has:editSystemUrl="()=>edit(1)">修改</a></div>
              </td> -->
          </tr>
          <tr>
            <td class="table_cell">socket合法域名</td>
            <td class="table_cell tl">
              <ul class="table_config_info_list">
                <li class="table_config_info_item" v-for="(v,k) in links.wsrequestdomain" :key="k">
                  {{v.hosturl}}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="table_cell">uploadFile合法域名</td>
            <td class="table_cell tl">
              <ul class="table_config_info_list">
                <li class="table_config_info_item" v-for="(v,k) in links.uploaddomain" :key="k">
                  {{v.hosturl}}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="table_cell">downloadFile合法域名</td>
            <td class="table_cell tl">
              <ul class="table_config_info_list">
                <li class="table_config_info_item" v-for="(v,k) in links.downloaddomain" :key="k">
                  {{v.hosturl}}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </a-card>
  <a-card title="业务域名">
    <!-- <a-button class='mb10' style="float:right">添加</a-button> -->
    <div class="table_wrp config_table">
      <table cellspacing="0" class="table">
        <thead class="thead">
          <tr>
            <th class="table_cell">域名</th>
            <th class="table_cell tl">说明</th>
            <!-- <th class="table_cell tr">操作</th> -->
          </tr>
        </thead>
        <tbody class="tbody">
          <tr>
            <td class="table_cell tl" style="width: 50%;">
              <ul class="table_config_info_list">
                <li class="table_config_info_item mb10" v-for="(v,k) in links.webviewdomain" :key="k">
                  {{v.hosturl}}
                </li>
              </ul>
            </td>
            <td class="table_cell tr" style="width: 50%;">
              <p class="tips_global">每个月可修改50次</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </a-card>
  <a-modal v-model:visible="webVisible" title="修改服务器域名" @ok="handleOk" centered :confirm-loading="confirmLoading"
    destroyOnClose>
    <a-form :model="form" :label-col="{ span:10}" :wrapper-col="{ span: 14 }">
      <a-form-item label="requestdomain合法域名">
        <a-textarea v-model:value="form.requestdomain" type="textarea" auto-size />
      </a-form-item>
      <a-form-item label="downloadFile合法域名">
        <a-textarea v-model:value="form.downloaddomain" type="textarea" auto-size />
      </a-form-item>
      <a-form-item label="uploadFile合法域名">
        <a-textarea v-model:value="form.uploaddomain" type="textarea" auto-size />
      </a-form-item>
      <a-form-item label="socket合法域名">
        <a-textarea v-model:value="form.wsrequestdomain" type="textarea" auto-size />
      </a-form-item>
      <a-form-item label="业务域名">
        <a-textarea v-model:value="form.webviewdomain" type="textarea" auto-size />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
  import { getAppletDomainList, getAppletDomain, setAppletDomain, sysDomainSynchro } from '@/api/appletCode'
  import { inject, onMounted, ref } from 'vue'
  import { $iscode } from '../../../../utils/app'
  import { message } from 'ant-design-vue'
  const def = {
    downloaddomain: '',
    requestdomain: '',
    uploaddomain: '',
    wsrequestdomain: ''
  }
  export default {
    setup() {
      const $route = inject('$route')
      var links = ref({})
      const initData = async () => {
        links.value = {}
        let result = await getAppletDomainList({ brand_id: $route.query.brand_id, app_id: $route.query.app_id ,miniapp_type:$route.query.miniapp_type?$route.query.miniapp_type:""}).then(res => res.data).catch(error => error)
        links.value = result.data;
      }
      onMounted(() => {
        initData()
      })
      const form = ref(def)
      const webVisible = ref(false)
      const edit = (flag) => {
        if (flag == 1) {
          form.value.downloaddomain = links.value.downloaddomain.map(v => v.hosturl).join(';')
          form.value.requestdomain = links.value.requestdomain.map(v => v.hosturl).join(';')
          form.value.uploaddomain = links.value.uploaddomain.map(v => v.hosturl).join(';')
          form.value.wsrequestdomain = links.value.wsrequestdomain.map(v => v.hosturl).join(';')
          form.value.webviewdomain = links.value.webviewdomain.map(v => v.hosturl).join(';')

          webVisible.value = true
        }
      }
      const handleOk = async () => {
        let result = await setAppletDomain({ ...form.value, brand_id: $route.query.brand_id, app_id: $route.query.app_id,miniapp_type:$route.query.miniapp_type?$route.query.miniapp_type:"" }).then(res => res.data).catch(error => error)
        if ($iscode(result, true)) {
          initData()
          webVisible.value = false
        } else {
          message.error(result.message || result.msg)
        }
      }
      const synchro = async () => {
        const hide = message.loading("正在加载中...", 0);
        let result = await sysDomainSynchro({ app_id: $route.query.app_id, brand_id: $route.query.brand_id,miniapp_type:$route.query.miniapp_type?$route.query.miniapp_type:"" }).then(res => res.data).catch(error => error)
        setTimeout(hide);
        if ($iscode(result, true)) {
          initData()
          webVisible.value = false
        } else {
          message.error(result.message || result.msg)
        }
      }
      return {
        links,
        edit,
        form,
        webVisible,
        handleOk,
        synchro
      }
    }
  }
</script>
<style lang="less" scoped>
  .table {
    width: 100%;
    font-size: 14px;
    background-color: #ffffff;
    text-align: center;

    .thead {
      background-color: #f6f8f9;
      color: #9a9a9a;

      .table_cell {
        border-left-width: 0;
        padding-right: 5px;
        padding: 10px;

        &:first-child {
          border-left-width: 0;
          text-align: left;
          padding-left: 40px;
        }

        &:last-child {
          text-align: right;
          padding-right: 40px;
        }
      }
    }

    tbody {
      .table_cell.base_info {
        width: 20em;
      }

      tr {
        .table_cell {
          border-bottom: none;
          height: auto;
          height: 52px;
          padding-top: 20px;
          padding-bottom: 20px;
          border-top: none;
          word-wrap: break-word;
          word-break: break-all;

          &:first-child {
            text-align: left;
            padding-left: 40px;
          }
        }

      }
    }
  }

  .tl {
    text-align: left;
  }

  .tr {
    text-align: right;
  }

  .meta_opr {
    margin-right: 40px;
  }
</style>