<template>
  <a-card :bordered="false">
    <a-tabs @change="callback">
      <a-tab-pane key="1" tab="版本管理">
        <version />
      </a-tab-pane>
      <a-tab-pane key="2" tab="域名管理">
        <domian />
      </a-tab-pane>
      <a-tab-pane key="3" tab="隐私协议">
        <personSeting />
      </a-tab-pane>
      <a-tab-pane key="4" tab="接口权限">
        <interfaceSet />
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>
<script>
  import version from './components/version'
  import domian from './components/domian'
  import personSeting from './components/personSeting'
  import interfaceSet from './components/interfaceSet'
  export default {
    components: { version, domian, personSeting ,interfaceSet}
  }
</script>